import useSWR from 'swr'
import { FormProvider, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useSWRMutation from 'swr/mutation'
import { useNavigate } from 'react-router-dom'
import { AxiosError } from 'axios'

import { Error, ErrorModal, Loader } from 'common/components'
import { usePageName } from 'common/hooks'
import { AppRoutes } from 'common/routing/routes'

import {
    AddExternalIdentifiersForm,
    addExternalIdentifiersFormData,
    AddExternalIdentifiersFormData,
    getAddExternalIdentifiersError,
    useCreateExternalIdentifiers,
    ExternalIdentifiersList,
    useGetAvailableExternalIdentifiers,
    getNumberQueryParameter
} from 'features/externalIdentifiers'

export function AdminExternalIdentifiers() {
    usePageName('pageHeaders.externalIdentifiers')

    const { t } = useTranslation()
    const navigate = useNavigate()

    const [submitError, setSubmitError] = useState('')

    const creator = useCreateExternalIdentifiers()

    const formMethods = useForm<AddExternalIdentifiersFormData>({
        reValidateMode: 'onChange',
        resolver: zodResolver(addExternalIdentifiersFormData)
    })

    const { trigger: createExternalIdentifiers, isMutating } = useSWRMutation(`/add-external-identifiers`, creator, {
        throwOnError: false,
        onError: (error: AxiosError<{ message: string }>) => {
            const errorMessage = error.response?.data.message

            const translatedError = t(getAddExternalIdentifiersError(errorMessage || ''))

            setSubmitError(translatedError as string)
        },
        onSuccess: () => {
            navigate(AppRoutes.AdminHome.getPath())
        }
    })

    const handleSubmit = (dataForm: AddExternalIdentifiersFormData) => {
        createExternalIdentifiers({
            numbers: dataForm.numbers
        })
    }

    const closeError = () => setSubmitError('')

    const fetcher = useGetAvailableExternalIdentifiers()

    const limit = getNumberQueryParameter('limit', 20)
    const pageNumber = getNumberQueryParameter('pageNumber', 1)

    const { data, error, isLoading } = useSWR(
        { url: '/admin/courses/external-numeration/available-numbers', limit, pageNumber },
        fetcher
    )

    if (isLoading) {
        return <Loader flexFullPage />
    }

    if (error) {
        console.error(error)
        return (
            <Error
                title="instructorCoursesList.getCoursesError.title"
                description="instructorCoursesList.getCoursesError.description"
            />
        )
    }

    return (
        <>
            <FormProvider {...formMethods}>
                <AddExternalIdentifiersForm
                    handleSubmit={formMethods.handleSubmit(handleSubmit)}
                    isMutating={isMutating}
                />
            </FormProvider>
            <ErrorModal
                confirmLabel={t('admin.externalIdentifiers.submitError.confirm')}
                description={submitError}
                isOpen={!!submitError}
                onClose={closeError}
                onConfirm={closeError}
                title={t('admin.externalIdentifiers.submitError.title')}
            />
            <ExternalIdentifiersList identifiersList={data || null} />
        </>
    )
}
