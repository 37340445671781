import { useApi } from 'common/hooks'
import { AvailableExternalIdentifier } from '../types/availableExternalIdentifiers'

type GetAvailableExternalIdentifiersParams = {
    url: string
    limit: number
    pageNumber: number
}

export function useGetAvailableExternalIdentifiers() {
    const api = useApi()

    return ({ url, limit, pageNumber }: GetAvailableExternalIdentifiersParams) => {
        const queryString = new URLSearchParams({
            limit: limit.toString(),
            pageNumber: pageNumber.toString()
        }).toString()

        return api.get<any, AvailableExternalIdentifier[]>(`${url}?${queryString}`)
    }
}
