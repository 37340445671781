import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import useSWRMutation from 'swr/mutation'
import { t } from 'i18next'

import { AvailableExternalIdentifier } from '../types/availableExternalIdentifiers'
import { useDeleteExternalIdentifier } from '../api/useDeleteExternalIdentifier'
import { AppRoutes } from 'common/routing/routes'
import { getNumberQueryParameter } from '../utils/getNumberQueryParameter'
import { DeleteButtonWithModal } from './DeleteButtonWithModal'

type ExternalIdentifiersListElementProps = {
    identifier: AvailableExternalIdentifier
}

export function ExternalIdentifiersListElement({ identifier }: ExternalIdentifiersListElementProps) {
    const navigate = useNavigate()
    const deleter = useDeleteExternalIdentifier(identifier.number.valueOf())
    const handleDelete = () => deleteUser()

    const { trigger: deleteUser } = useSWRMutation('/delete-external-identifier', deleter, {
        onError: () => {
            console.log('error!')
        },
        onSuccess: () => {
            window.location.reload()
        },
        throwOnError: false
    })

    return (
        <li>
            <ContentWrapper>
                {identifier.number.toString()} (dodany: {identifier.createdAtFormattedDate.toString()})
                <DeleteButtonWithModal
                    onClick={handleDelete}
                    cancelLabel={t('admin.externalIdentifiers.deleteExternalIdentifier.cancelLabel')}
                    confirmLabel={t('admin.externalIdentifiers.deleteExternalIdentifier.confirmLabel')}
                    description={t('admin.externalIdentifiers.deleteExternalIdentifier.modalDescription', {
                        externalIdentifier: identifier.number.valueOf()
                    })}
                    title={t('admin.externalIdentifiers.deleteExternalIdentifier.modalTitle')}
                />
            </ContentWrapper>
        </li>
    )
}

const ContentWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`
