import { useState } from 'react'
import styled from 'styled-components'

import { ConfirmModal } from 'common/components'
import { TrashIcon } from 'common/assets/TrashIcon'

type DeleteButtonWithModalProps = {
    onClick: () => void
    cancelLabel: string
    confirmLabel: string
    description: string
    title: string
}

export function DeleteButtonWithModal({
    onClick,
    cancelLabel,
    confirmLabel,
    description,
    title
}: DeleteButtonWithModalProps) {
    const [showModal, setShowModal] = useState(false)

    const onHideModal = () => setShowModal(false)
    const onShowModal = () => setShowModal(true)

    const handleConfirm = () => {
        onHideModal()
        onClick()
    }

    return (
        <>
            <StyledIcon onClick={onShowModal}>
                <TrashIcon />
            </StyledIcon>
            <ConfirmModal
                cancelLabel={cancelLabel}
                confirmLabel={confirmLabel}
                description={description}
                isOpen={showModal}
                onClose={onHideModal}
                onConfirm={handleConfirm}
                title={title}
            />
        </>
    )
}

const StyledIcon = styled.div``
