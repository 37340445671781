import styled from 'styled-components'
import { t } from 'i18next'

import { Button, NoDataElement } from 'common/components'
import { ExternalIdentifiersListElement } from './ExternalIdentifiersListElement'
import { AvailableExternalIdentifier } from '../types/availableExternalIdentifiers'
import { Link } from 'react-router-dom'
import { AppRoutes } from 'common/routing/routes'
import { getNumberQueryParameter } from '../utils/getNumberQueryParameter'

type ExternalIdentifiersListProps = {
    identifiersList: AvailableExternalIdentifier[] | null
}

export function ExternalIdentifiersList({ identifiersList }: ExternalIdentifiersListProps) {
    const limit = getNumberQueryParameter('limit', 20)
    const pageNumber = getNumberQueryParameter('pageNumber', 1)

    const isPreviousButtonDisabled = pageNumber === 1
    const isNextButtonDisabled = (identifiersList?.length || 0) < limit

    let previousPageUrl = ''
    if (!isPreviousButtonDisabled) {
        const previousPageNumber = pageNumber.valueOf() - 1
        previousPageUrl = AppRoutes.AdminExternalIdentifiers.getPath()
            .concat(`?limit=${limit}&pageNumber=`)
            .concat(previousPageNumber.toString())
    }

    let nextPageUrl = ''
    if (!isNextButtonDisabled) {
        const nextPageNumber = pageNumber.valueOf() + 1
        nextPageUrl = AppRoutes.AdminExternalIdentifiers.getPath()
            .concat(`?limit=${limit}&pageNumber=`)
            .concat(nextPageNumber.toString())
    }

    return (
        <Wrapper>
            <Title>{t('admin.externalIdentifiers.availableNumbersTitle')}</Title>
            {identifiersList?.length ? (
                <IdentifierList>
                    {identifiersList?.map((identifier: AvailableExternalIdentifier) => (
                        <ExternalIdentifiersListElement key={identifier.number.valueOf()} identifier={identifier} />
                    ))}
                </IdentifierList>
            ) : (
                <NoDataElement title={t('admin.externalIdentifiers.noAvailableNumbersTitle')} />
            )}
            <LinkElementWrapper>
                <LinkElement to={previousPageUrl}>
                    <PreviousButton type="submit" disabled={isPreviousButtonDisabled}>
                        &#8592;
                    </PreviousButton>
                </LinkElement>
                <LinkElement to={nextPageUrl}>
                    <NextButton type="submit" disabled={isNextButtonDisabled}>
                        &#8594;
                    </NextButton>
                </LinkElement>
            </LinkElementWrapper>
        </Wrapper>
    )
}

const LinkElement = styled(Link)`
    width: 50%;
`

const Wrapper = styled.section`
    display: flex;
    flex-direction: column;

    width: 100%;
`

const LinkElementWrapper = styled.section`
    display: flex;
    flex-direction: row;
    gap: 10px;

    width: 100%;
`

const Title = styled.h5`
    font-size: 1.25rem;
    font-weight: 700;

    margin: 0.5rem 0 0.75rem 0;
`

const IdentifierList = styled.ul`
    list-style: disc;

    margin: 0 0 0 1rem;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 0.75rem;
`

const PreviousButton = styled(Button)`
    width: 100%;
    margin-top: 0.25rem;
`

const NextButton = styled(Button)`
    width: 100%;
    margin-top: 0.25rem;
`
